import React from "react";
import "./index.scss";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
} from "antd";
import {
  dataRenBuses,
  gift,
  motorbikes,
  rooms,
  tours,
} from "../../utils/datasTest";
import { emailValidate, urlMain } from "../../utils/\bconstants";
import { PhoneInput } from "react-international-phone";
import TextArea from "antd/es/input/TextArea";
import { isPhoneValid } from "../../utils/validations";
import { postDataAPI } from "../../utils/fetchData";
import useViewPort from "../../hooks/useViewPort";
import * as dayjs from "dayjs";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function FormBook() {
  const [tabs, setTabs] = React.useState([
    { name: "Choose Tour", status: true },
    { name: "Homestay", status: false },
    { name: "Bus Ticket", status: false },
    { name: "Motorbike Rental", status: false },
    { name: "Gift", status: false },
    { name: "Booking Information", status: false },
  ]);

  const [form] = Form.useForm();

  const [formConf] = Form.useForm();

  const location = useLocation();

  const [values, setValues] = React.useState({});

  const [finishVals, setFinishVals] = React.useState({});
  const [isFail, setIsFail] = React.useState(false);
  const isMobile = useViewPort();

  const submitcheck = () => {
    form.submit();
    return;
  };

  const total = (val) => {
    const max_date = tours.find(
      (i) => i.clear_des_title === val.name_tour
    )?.max_date;
    return `${Number(
      val.amount > 3
        ? val.amount * (Number(val.type.split("-")[1]) - 250000)
        : val.amount * Number(val.type.split("-")[1]) +
            Number(val.motor?.pricevnd) *
              (max_date ? max_date : 1) *
              val.amount +
            Number(val.homestay.pricevnd) *
              (max_date ? max_date - 1 : 1) *
              val.amount_room +
            (val.bus_direct.name_bus?.pricevnd
              ? Number(val.bus_direct.name_bus?.pricevnd) * val.amount
              : 0) +
            (val.bus_return.name_bus?.pricevnd
              ? Number(val.bus_return.name_bus?.pricevnd) * val.amount
              : 0)
    ).toLocaleString("en-US")}`;
  };

  const txtShowTabl = (v, key) => {
    let htmlS;

    switch (key) {
      case "name_tour":
        htmlS = (
          <>
            {v.name_tour ? (
              <div className="et-tbal d-flex">
                <p>
                  Name: {v.name_tour} - {v.type.split("-")[0]}
                </p>
                <p className="pri">
                  {`${
                    v.amount > 3
                      ? `${(
                          Number(v.type.split("-")[1]) - 250000
                        ).toLocaleString("en-US")}`
                      : `${Number(v.type.split("-")[1]).toLocaleString(
                          "en-US"
                        )}`
                  }đ`}{" "}
                  ~ {v.type.split("-")[2]}
                </p>
              </div>
            ) : null}
          </>
        );
        break;
      case "amount":
        htmlS = (
          <>
            {v.amount ? (
              <div className="et-tbal d-flex">
                <p>
                  Amount of People: {v.amount}
                  {v.amount > 3 ? ` - Discount: 250,000 VND` : " "}
                </p>
              </div>
            ) : null}
          </>
        );
        break;
      case "start_date":
        htmlS = (
          <div className="et-tbal d-flex">
            {v.start_date ? (
              <p>Date Departure: {dayjs(v.start_date).format("DD/MM/YYYY")}</p>
            ) : null}
          </div>
        );
        break;
      case "homestay":
        htmlS = (
          <>
            {v.homestay ? (
              <div className="et-tbal d-flex">
                <p>Homestay: {v.homestay?.name}</p>
                <p className="pri">
                  {Number(v.homestay?.pricevnd).toLocaleString("en-US")}đ ~ $
                  {v.homestay?.pricedola}
                </p>
              </div>
            ) : null}
          </>
        );
        break;
      case "motor":
        htmlS = (
          <>
            {v.motor ? (
              <div className="et-tbal d-flex">
                <p>Rental motorbike: {v.motor?.name}</p>
                <p className="pri">
                  {Number(v.motor?.pricevnd).toLocaleString("en-US")}đ ~ $
                  {v.motor?.pricedola}
                </p>
              </div>
            ) : null}
          </>
        );
        break;

      case "bus_direct":
        htmlS = (
          <>
            {v?.bus?.includes("direct") &&
            !Object.keys(v.bus_direct).find((k) => !v.bus_direct[k]) ? (
              <div className="et-tbal d-flex">
                <p>
                  {`Direction: ${v.bus_direct?.name_des} - Ha Giang`}
                  <br />
                  {`Details: ${v.bus_direct?.time_bus} - ${dayjs(
                    v.bus_direct?.date_departure
                  ).format("DD/MM/YYYY")} - ${v.bus_direct?.name_bus?.name}`}
                </p>
                <p className="pri">
                  {Number(v.bus_direct?.name_bus?.pricevnd).toLocaleString(
                    "en-US"
                  )}
                  đ ~ ${v.bus_direct?.name_bus?.pricedola}
                </p>
              </div>
            ) : null}
          </>
        );
        break;
      case "bus_return":
        htmlS = (
          <>
            {v?.bus?.includes("return") &&
            !Object.keys(v.bus_return).find((k) => !v.bus_return[k]) ? (
              <div className="et-tbal d-flex">
                <p>
                  {`Return: Ha Giang - ${v.bus_return?.name_des}`}
                  <br />
                  {`Details: ${v.bus_return?.time_bus} - ${dayjs(
                    v.bus_return?.date_departure
                  ).format("DD/MM/YYYY")} - ${v.bus_return?.name_bus?.name}`}
                </p>
                <p className="pri">
                  {Number(v.bus_return?.name_bus?.pricevnd).toLocaleString(
                    "en-US"
                  )}
                  đ ~ ${v.bus_return?.name_bus?.pricedola}
                </p>
              </div>
            ) : null}
          </>
        );
        break;

      case "gift":
        htmlS = (
          <>
            {v?.gift ? (
              <div className="et-tbal d-flex">
                <p>Gift: {v.gift?.name}</p>{" "}
              </div>
            ) : null}
          </>
        );
        break;

      default:
        break;
    }

    return htmlS;
  };

  const onClickMovement = (keyc) => {
    const newarr = [...tabs];

    const index = newarr.findIndex((i) => i.status);

    let condition;
    let compare;

    switch (keyc) {
      case "pre":
        condition = index - 1;
        compare = condition >= 0;
        break;
      case "next":
        condition = index + 1;
        compare = condition <= newarr.length - 1;
        break;
      default:
        break;
    }

    newarr.forEach((o, k) => {
      if (compare) {
        if (k !== condition) {
          o.status = false;
        } else {
          o.status = true;
        }
      }
    });

    if (condition === 5) {
      submitcheck();
    }

    setTabs(newarr);
  };

  const [valuesConfirm, setValuesConfirm] = React.useState({});

  const isValid = valuesConfirm["phone"]
    ? isPhoneValid(valuesConfirm["phone"])
    : true;

  const onSubmitConfirm = async (v, valtrip) => {
    const sendmail = `
    <h2>Liên Hệ Đặt Tour</h2>

    <p>Tên: ${v.name}</p>
    <p>Email: ${v.email}</p>
    <p>Email: ${v.address}</p>
    <p>Nội dung: ${v.message ? v.message : "None"}</p>
    <p>Phone: ${v.phone}</p>
    <h2>Tour Detail</h2>
    <p>Tên gói: ${valtrip.name_tour}</p>
    <p>Loại: ${valtrip.type}</p>
    <p>Ngày khởi hành: ${dayjs(valtrip.start_date).format("DD/MM/YYYY")}</p>
    <p>Vé bus ngày khởi hành: ${
      valtrip.bus_direct.name_des
        ? `${valtrip.bus_direct.name_des} -
            ${valtrip.bus_direct.time_bus} -
            ${valtrip.bus_direct.name_bus?.name} -
            ${valtrip.bus_direct.name_bus?.pricevnd} -
            ${dayjs(valtrip.bus_direct.date_departure).format("DD/MM/YYYY")}`
        : "None"
    }</p>
    <p>Vé bus ngày trở về: ${
      valtrip.bus_return.name_des
        ? `${valtrip.bus_return.name_des} -
            ${valtrip.bus_return.time_bus} -
            ${valtrip.bus_return.name_bus?.name} -
            ${valtrip.bus_return.name_bus?.pricevnd} -
            ${dayjs(valtrip.bus_return.date_departure).format("DD/MM/YYYY")}`
        : "None"
    }</p>
    <p>Phòng ở: ${valtrip.homestay.name} - ${
      valtrip.homestay.pricevnd
    } - Số lượng phòng: ${valtrip.amount_room}</p>
    <p>Xe thuê: ${valtrip.motor.name} - ${valtrip.motor.pricevnd}</p>
    <p>Quà tặng: ${valtrip.gift?.name ? valtrip.gift?.name : "None"}</p>
    <p>Số lượng người: ${valtrip.amount}</p>
    <p>Tổng chi phí: ${total(valtrip)}</p>
    `;

    try {
      await postDataAPI("auth/send-mail", {
        email: "info.hgmotorbiketours@gmail.com",
        title: `Liên hệ từ ${v.name}`,
        content: sendmail,
      });
      message.success("Gửi liên hệ thành công!");
      form.resetFields();
      formConf.resetFields();
      setTabs((v) => {
        const news = [...v];

        const index = news.findIndex((o, k) => k === 0);
        news[index].status = true;

        news.forEach((n, k) => {
          if (k !== index) {
            n.status = false;
          }
        });
        return news;
      });
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto", // Optional if you want to skip the scrolling animation
      });
    } catch (error) {
      message.error("Kiểm tra lại dữ liệu gửi hoặc lỗi hệ thống");
    }
  };

  return (
    <div className="wrap-form-book">
      <Helmet>
        <title>{"Book Form Tour"}</title>
        <link rel="canonical" href={`${urlMain}${location.pathname}`} />
      </Helmet>
      <div>
        <div className="container">
          <h1>Book Tour Now</h1>
          <p>
            Please provide the necessary information for your trip and be sure
            to check carefully before sending.
          </p>
          {isMobile.offsets.width <= 480 && (
            <p className="txt-mobile-form">{tabs.find((i) => i.status).name}</p>
          )}

          <div className="row">
            <div className="col-4">
              <ul>
                {tabs.map((i, k) => (
                  <li
                    key={k}
                    className={`d-flex ${i.status ? "active-t" : ""}`}
                    onClick={() => {
                      const news = [...tabs];

                      const index = news.findIndex((o) => o.name === i.name);
                      news[index].status = true;

                      news.forEach((n) => {
                        if (n.name !== i.name) {
                          n.status = false;
                        }
                      });

                      if (index === 5) {
                        submitcheck();
                      }

                      setTabs(news);
                    }}
                  >
                    <p>{i.name}</p>
                    {i.status && <FaCircleChevronLeft />}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-8">
              <div className="tab-movement d-flex">
                <div
                  className="tab-movement-left"
                  onClick={() => onClickMovement("pre")}
                >
                  <BsArrowLeft />
                  <p>Previous</p>
                </div>

                {tabs.find(
                  (i) => i.name === "Booking Information" && i.status
                ) ? (
                  <></>
                ) : (
                  <div
                    className="tab-movement-left"
                    onClick={() => onClickMovement("next")}
                  >
                    <p>Next</p>
                    <BsArrowRight />
                  </div>
                )}
              </div>
              <div className="form-book">
                <Form
                  onValuesChange={(v, all) => setValues(all)}
                  form={form}
                  layout="vertical"
                  autoComplete="off"
                  onFinishFailed={(v) => {
                    if (v.errorFields.length > 0) {
                      message.error("Opp, you need to check all info again!");
                      setIsFail(true);
                    } else {
                      setIsFail(false);
                    }
                  }}
                  onFinish={(v) => {
                    setFinishVals(v);
                    if (
                      (v?.bus?.includes("direct") &&
                        Object.keys(v?.bus_direct).find(
                          (i) => !v?.bus_direct[i]
                        )) ||
                      (v?.bus?.includes("return") &&
                        Object.keys(v?.bus_return).find(
                          (i) => !v?.bus_return[i]
                        ))
                    ) {
                      setIsFail(true);
                      message.error(
                        "Opp, you need to check info direction again!"
                      );
                    } else {
                      setIsFail(false);
                    }
                  }}
                  // autoComplete="off"
                >
                  <div style={{ display: tabs[0].status ? "initial" : "none" }}>
                    <Form.Item
                      label="Tour"
                      name={"name_tour"}
                      rules={[
                        {
                          required: true,
                          message: "Please choose one tour!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        onSelect={(v) => {
                          form.setFieldValue("type", null);
                        }}
                        onClear={(v) => {
                          form.setFieldValue("type", null);
                        }}
                        options={tours.map((i) => ({
                          value: i.clear_des_title,
                          label: i.clear_des_title,
                        }))}
                        placeholder="Choose a tour"
                      />
                    </Form.Item>
                    <Form.Item
                      name={"type"}
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Please choose one type tour!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        options={tours
                          .find((i) => i.clear_des_title === values?.name_tour)
                          ?.packages.map((o) => ({
                            value: `${o.name} - ${o.pricevnd} - $${o.pricedola}`,
                            label: `${o.name} - ${o.pricevnd} - $${o.pricedola}`,
                          }))}
                        placeholder="Choose a type"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Star Date"
                      name="start_date"
                      rules={[
                        { required: true, message: "Please select a date!" },
                      ]}
                    >
                      <DatePicker
                        format={"YYYY/MM/DD"}
                        defaultDate={dayjs()}
                        disabledDate={(c) => c.isBefore(dayjs().endOf("day"))}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Amount People"
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: "Please type amount people!",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        max={12}
                        placeholder="Amount of People"
                      />
                    </Form.Item>
                  </div>
                  <div style={{ display: tabs[1].status ? "initial" : "none" }}>
                    <Form.Item
                      name="homestay"
                      rules={[
                        {
                          required: true,
                          message: "Please choose one homestay!",
                        },
                      ]}
                    >
                      <Radio.Group className="radiofix">
                        {rooms.map((i, k) => (
                          <Radio
                            value={i}
                            key={k}
                            style={{
                              marginTop: "20px",
                              width: "45%",
                              display: "inline-block",
                            }}
                          >
                            <p>{i.name}</p>
                            <p>
                              {Number(i.pricevnd).toLocaleString("en-US")}
                              đ/night ≈ <span>{i.pricedola} USD</span>
                            </p>

                            {/* <img
                              src={i.url}
                              alt="anhphong"
                            /> */}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Amount of Room"
                      name="amount_room"
                      rules={[
                        {
                          required: true,
                          message: "Please type amount room!",
                        },
                      ]}
                      style={{
                        width: isMobile.offsets.width <= 480 ? "100%" : "27%",
                      }}
                    >
                      <InputNumber min={1} placeholder="Amount of Room" />
                    </Form.Item>

                    <p className="note-spec">
                      <span>Note:</span> Rooms may be subject to change
                      depending on departure location, so this will be discussed
                      further when Sun Flower contacts you after booking.
                    </p>
                  </div>
                  <div style={{ display: tabs[2].status ? "initial" : "none" }}>
                    <Form.Item name="bus">
                      <Checkbox.Group className="check-box-w">
                        <Checkbox
                          value="direct"
                          onChange={(e) =>
                            !e.target.checked &&
                            form.setFieldValue("bus_direct", null)
                          }
                        >
                          Choose a direction
                        </Checkbox>

                        <Form.Item name={["bus_direct", "name_des"]}>
                          <Select
                            allowClear
                            onChange={() => {
                              form.setFieldValue(
                                ["bus_direct", "name_bus"],
                                null
                              );
                              form.setFieldValue(
                                ["bus_direct", "time_bus"],
                                null
                              );
                            }}
                            disabled={
                              form.getFieldValue("bus")?.includes("direct")
                                ? false
                                : true
                            }
                            options={dataRenBuses.map((i) => ({
                              value: i.des_name,
                              label: i.des_name,
                            }))}
                            placeholder="Choose a name"
                          />
                        </Form.Item>

                        <div className="form-ng d-flex justify-content-between align-items-center">
                          <Form.Item name={["bus_direct", "time_bus"]}>
                            <Select
                              allowClear
                              disabled={
                                form.getFieldValue("bus_direct")?.name_bus
                                  ? false
                                  : true
                              }
                              options={form
                                .getFieldValue("bus_direct")
                                ?.name_bus?.time_range?.map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                              placeholder="Choose time"
                            />
                          </Form.Item>
                          <Form.Item name={["bus_direct", "date_departure"]}>
                            <DatePicker
                              disabled={
                                form.getFieldValue("bus")?.includes("direct")
                                  ? false
                                  : true
                              }
                              format={"YYYY/MM/DD"}
                              defaultDate={dayjs()}
                              disabledDate={(c) =>
                                c.isBefore(dayjs().endOf("day"))
                              }
                            />
                          </Form.Item>
                        </div>

                        <Form.Item name={["bus_direct", "name_bus"]}>
                          <Radio.Group
                            className="radiofix"
                            onChange={() => {
                              form.setFieldValue(
                                ["bus_direct", "time_bus"],
                                null
                              );
                            }}
                          >
                            {dataRenBuses
                              .filter(
                                (i) =>
                                  i.des_name ===
                                  form.getFieldValue("bus_direct")?.name_des
                              )[0]
                              ?.contents.filter((i) => i.kind === "direct")[0]
                              .buses.map((i, k) => (
                                <Radio value={i} key={k}>
                                  <p>{i.name}</p>
                                  <p>
                                    {Number(i.pricevnd).toLocaleString("en-US")}
                                    đ ≈ <span>{i.pricedola} USD</span>
                                  </p>

                                  <img src={i.url} alt="anhxe" />
                                </Radio>
                              ))}
                          </Radio.Group>
                        </Form.Item>

                        <Checkbox
                          value="return"
                          onChange={(e) =>
                            !e.target.checked &&
                            form.setFieldValue("bus_return", null)
                          }
                        >
                          Choose a return direction
                        </Checkbox>

                        <Form.Item name={["bus_return", "name_des"]}>
                          <Select
                            allowClear
                            onChange={() => {
                              form.setFieldValue(
                                ["bus_return", "name_bus"],
                                null
                              );
                              form.setFieldValue(
                                ["bus_return", "time_bus"],
                                null
                              );
                            }}
                            disabled={
                              form.getFieldValue("bus")?.includes("return")
                                ? false
                                : true
                            }
                            options={dataRenBuses.map((i) => ({
                              value: i.des_name,
                              label: i.des_name,
                            }))}
                            placeholder="Choose a name"
                          />
                        </Form.Item>

                        <div className="form-ng d-flex justify-content-between align-items-center">
                          <Form.Item name={["bus_return", "time_bus"]}>
                            <Select
                              allowClear
                              disabled={
                                form.getFieldValue("bus_return")?.name_bus
                                  ? false
                                  : true
                              }
                              options={form
                                .getFieldValue("bus_return")
                                ?.name_bus?.time_range?.map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                              placeholder="Choose time"
                            />
                          </Form.Item>
                          <Form.Item name={["bus_return", "date_departure"]}>
                            <DatePicker
                              disabled={
                                form.getFieldValue("bus")?.includes("return")
                                  ? false
                                  : true
                              }
                              format={"YYYY/MM/DD"}
                              defaultDate={dayjs()}
                              disabledDate={(c) =>
                                c.isBefore(dayjs().endOf("day"))
                              }
                            />
                          </Form.Item>
                        </div>

                        <Form.Item name={["bus_return", "name_bus"]}>
                          <Radio.Group
                            className="radiofix"
                            onChange={() => {
                              form.setFieldValue(
                                ["bus_return", "time_bus"],
                                null
                              );
                            }}
                          >
                            {dataRenBuses
                              .filter(
                                (i) =>
                                  i.des_name ===
                                  form.getFieldValue("bus_return")?.name_des
                              )[0]
                              ?.contents.filter((i) => i.kind === "return")[0]
                              .buses.map((i, k) => (
                                <Radio value={i} key={k}>
                                  <p>{i.name}</p>
                                  <p>
                                    {Number(i.pricevnd).toLocaleString("en-US")}
                                    đ ≈ <span>{i.pricedola} USD</span>
                                  </p>

                                  <img src={i.url} alt="anhxe" />
                                </Radio>
                              ))}
                          </Radio.Group>
                        </Form.Item>
                      </Checkbox.Group>
                    </Form.Item>
                    {/* <p className="note-spec">
                      <span>Note:</span> Lorem is spum lot e
                    </p> */}
                  </div>
                  <div style={{ display: tabs[3].status ? "initial" : "none" }}>
                    <Form.Item
                      name="motor"
                      rules={[
                        {
                          required: true,
                          message: "Please choose one motor!",
                        },
                      ]}
                    >
                      <Radio.Group className="radiofix">
                        {motorbikes.map((i, k) => (
                          <Radio
                            value={i}
                            key={k}
                            style={{
                              marginTop: "20px",
                              width: "45%",
                              display: "inline-block",
                            }}
                          >
                            <p>{i.name}</p>
                            <p>
                              {Number(i.pricevnd).toLocaleString("en-US")}đ/day
                              ≈ <span>{i.pricedola} USD</span>
                            </p>

                            <img src={i.url} alt="anhxe" />
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div style={{ display: tabs[4].status ? "initial" : "none" }}>
                    <Form.Item name="gift">
                      <Radio.Group className="radiofix">
                        {gift.map((i, k) => (
                          <Radio
                            value={i}
                            key={k}
                            style={{
                              marginTop: "20px",
                              width: "45%",
                              display: "inline-block",
                            }}
                          >
                            <p>{i.name}</p>
                            <p>
                              {Number(i.pricevnd).toLocaleString("en-US")}đ ≈{" "}
                              <span>{i.pricedola} USD</span>
                            </p>

                            <img src={i.url} alt="anhxe" />
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </Form>
              </div>
              <div
                className="confirm-b"
                style={{ display: tabs[5].status ? "initial" : "none" }}
              >
                <div className="row">
                  <div className="col-12">
                    <h4>Packages</h4>

                    {Object.keys(finishVals).length > 0 && !isFail ? (
                      <>
                        <div>
                          {Object.keys(finishVals).map((k, keyk) => (
                            <div key={keyk}>{txtShowTabl(finishVals, k)}</div>
                          ))}
                        </div>
                        <div className="d-flex total">
                          <p>Total of trip </p>
                          <div>
                            <p>VND {total(finishVals)}</p>
                            <p>
                              ~ $
                              {Math.floor(
                                Number(
                                  total(finishVals)
                                    .split(",")
                                    .filter((i) => i !== ",")
                                    .join("")
                                ) / 24670
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="form-confirm-m">
                          <Form
                            variant="filled"
                            layout="vertical"
                            form={formConf}
                            onValuesChange={(v) => setValuesConfirm(v)}
                            onFinish={(v) => onSubmitConfirm(v, finishVals)}
                            autoComplete="off"
                          >
                            <Form.Item
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please type your name!",
                                },
                              ]}
                            >
                              <Input placeholder="Your Name" />
                            </Form.Item>
                            <Form.Item
                              name="address"
                              rules={[
                                {
                                  required: true,
                                  message: "Please type your address!",
                                },
                              ]}
                            >
                              <Input placeholder="Your Address" />
                            </Form.Item>
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your email address!",
                                },
                                {
                                  pattern: new RegExp(emailValidate),
                                  message:
                                    "Please enter your right email format!",
                                },
                              ]}
                            >
                              <Input placeholder="a@gmail.com" />
                            </Form.Item>

                            <Form.Item
                              name="phone"
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator() {
                                    if (
                                      getFieldValue("phone").length > 1 &&
                                      isValid
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      "Please type right format phone number!"
                                    );
                                  },
                                }),
                              ]}
                            >
                              <PhoneInput
                                defaultCountry="vn"
                                disableFormatting={true}
                                inputStyle={{
                                  height: "56px",
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  border: "none",
                                  width: "100%",
                                }}
                                countrySelectorStyleProps={{
                                  buttonStyle: {
                                    height: "56px",
                                    padding: "0 7px",
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    border: "none",
                                  },
                                  buttonContentWrapperStyle: {},
                                }}
                                onChange={(phone) => {
                                  // form.setFieldValue("phone", phone);
                                  isPhoneValid(phone);
                                }}
                              />
                            </Form.Item>
                            <Form.Item name="message">
                              <TextArea
                                placeholder="Some Message..."
                                showCount
                                style={{ height: "185px" }}
                              />
                            </Form.Item>

                            <Form.Item className="btnsm-wrap">
                              <Button
                                className="btn-submit-j"
                                type="primary"
                                htmlType="submit"
                              >
                                Send Message
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      </>
                    ) : (
                      <p>None of data</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
