import React from "react";
import "./index.scss";
import BannerFiForPage from "../../components/_shared/ui-blocks/BannerFiForPage";
import { Input, Skeleton } from "antd";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import { dataDefaultFetch, postDataAPI } from "../../utils/fetchData";
import {
  regexRemoveSomeCharacters,
  white_space_String,
} from "../../utils/validations";
import * as dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { urlMain } from "../../utils/\bconstants";

const { Search } = Input;

export default function Blog() {
  const [searchv, setSearchV] = React.useState(null);
  const refS = React.useRef(null);
  const [filters, setFilters] = React.useState({
    page: 0,
    limit_page: 3,
    filters: { is_delete: false },
  });
  const [blogs, setBlogs] = React.useState(dataDefaultFetch);
  const [blogsFets, setBlogsFet] = React.useState(dataDefaultFetch);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    (async () => {
      setBlogs({ ...blogs, loading: true });
      try {
        const res = await postDataAPI("blog/all", filters);

        setBlogs({
          ...blogs,
          data: res.data.data,
          loading: false,
          total: res.data.total,
        });
        setTimeout(() => {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }, 1000);
      } catch (error) {
        setBlogs({ ...blogs, data: null, loading: false, error: error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  React.useEffect(() => {
    (async () => {
      setBlogsFet({ ...blogsFets, loading: true });
      try {
        const res = await postDataAPI("blog/all", {
          page: 0,
          limit_page: 3,
          filters: { is_delete: false },
        });

        setBlogsFet({
          ...blogs,
          data: res.data.data,
          loading: false,
          total: res.data.total,
        });
      } catch (error) {
        setBlogsFet({ ...blogsFets, data: null, loading: false, error: error });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (e) => {
    const reset = { ...filters };
    if (!e) {
      delete reset.filters["$text"];
    } else {
      reset.filters["$text"] = { $search: white_space_String(e) };
    }
    reset.page = 0;
    setFilters(reset);
    refS.current.blur();
  };

  const totalPage = (totalData) => {
    let page = 0;
    if (totalData) {
      page =
        Number(totalData / filters.limit_page) % 1 != 0
          ? Math.floor(totalData / filters.limit_page)
          : totalData / filters.limit_page - 1;
    } else {
      page = 0;
    }
    return page;
  };

  const handlePageNum = (key, total) => {
    const reset = { ...filters };

    switch (key) {
      case "next":
        reset.page = reset.page + 1;
        break;
      case "pre":
        reset.page = reset.page - 1;
        break;

      default:
        break;
    }

    if (reset.page < 0) {
      reset.page = 0;
    } else if (reset.page > totalPage(total)) {
      reset.page = totalPage(total);
    }
    setFilters(reset);
  };
  return (
    <div className="blog-container">
      <Helmet>
        <title>{"News"}</title>
        <link rel="canonical" href={`${urlMain}${location.pathname}`} />
      </Helmet>
      <BannerFiForPage title={"News"} />
      <div className="container">
        <div className="row mr-80">
          <div className="col-8">
            {!blogs.loading && blogs.data ? (
              blogs.data.length > 0 ? (
                blogs.data.map((i, k) => (
                  <div className="wrap-box-sinblog" key={k}>
                    <img
                      src={i.img.url}
                      alt={`annh blog ${k}`}
                      onClick={() => {
                        navigate(
                          `/blog/detail/${String(i.title).trim().replace(/\s+/g, "-").replace(regexRemoveSomeCharacters, "")}`,
                          {
                            state: { title: i.title, id: i._id },
                          }
                        );
                      }}
                    />

                    <div className="d-flex">
                      <div className="tag">
                        <p>{i.categories}</p>
                      </div>
                      <div className="tag-time">
                        <p>{dayjs(i.createdAt).format("DD/MM/YYYY")}</p>
                      </div>
                    </div>
                    <h2
                      onClick={() => {
                        navigate(
                          `/blog/detail/${String(i.title).trim().replace(/\s+/g, '-').replace(
                            regexRemoveSomeCharacters,
                            ""
                          )}`,
                          {
                            state: { title: i.title, id: i._id },
                          }
                        );
                      }}
                    >
                      {i.title}
                    </h2>

                    <div className="desc-wr">{i.short_content}</div>

                    <p
                      className="rm"
                      onClick={() => {
                        navigate(`/blog/detail/${String(i.title).trim().replace(/\s+/g, '-').replace(
                          regexRemoveSomeCharacters,
                          ""
                        )}`, {
                          state: { title: i.title, id: i._id },
                        });
                      }}
                    >
                      Read more
                    </p>
                  </div>
                ))
              ) : (
                <p>None of any blog here!</p>
              )
            ) : (
              <Skeleton />
            )}

            <div className="pagination">
              <ul className="d-flex">
                <li
                  onClick={() =>
                    filters.page === 0
                      ? false
                      : handlePageNum("pre", blogs?.total)
                  }
                >
                  <FaArrowLeftLong />
                </li>
                <li>
                  <span>{filters.page + 1}</span>
                </li>
                <li
                  onClick={() =>
                    filters.page === totalPage(blogs?.total)
                      ? false
                      : handlePageNum("next", blogs?.total)
                  }
                >
                  <FaArrowRightLong />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-4">
            <Search
              placeholder="Search blogs here...."
              onSearch={onSearch}
              ref={refS}
              enterButton
              value={searchv}
              onChange={(e) => {
                setSearchV(e.target.value);
              }}
            />
            <div className="left-content-t">
              <h6>Recent Posts</h6>
              <ul>
                {!blogsFets.loading && blogsFets.data ? (
                  blogsFets.data?.length > 0 ? (
                    blogsFets.data.map((i, k) => (
                      <li key={k} className="d-flex">
                        <div className="i-lc-left">
                          <img src={i.img.url} alt={`annh blog ${k}`} />
                        </div>
                        <div className="i-lc-right">
                          <p>{i.title}</p>
                          <p>{dayjs(i.createdAt).format("DD/MM/YYYY")}</p>
                          <p
                            onClick={() => {
                              navigate(`/blog/detail/${String(i.title).trim().replace(/\s+/g, '-').replace(
                                regexRemoveSomeCharacters,
                                ""
                              )}`, {
                                state: { title: i.title, id: i._id },
                              });
                            }}
                          >
                            Read more {">>"}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>None of any blog here!</li>
                  )
                ) : (
                  <Skeleton />
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
