import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import DataProvider from "./redux/store";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactGA.initialize("G-780N0ENN8J");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <DataProvider>
        <ScrollToTop />
        <App />
      </DataProvider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
