import React from "react";
import "./index.scss";
import { BGTour2, MapIc, TourSecSVG1, TourSecSVG2 } from "../../assets";
import { IoIosArrowRoundForward } from "react-icons/io";
import {
  // dataCus,
  slidesTour,
  tours,
} from "../../utils/datasTest";
import { HiLocationMarker } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper/modules";
import Fancybox from "../../components/Fancybox";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { urlMain } from "../../utils/\bconstants";
// import { HiMiniArrowLongLeft, HiMiniArrowLongRight } from "react-icons/hi2";

export default function Tour() {
  const [activeT, setActiveT] = React.useState(0);
  const [activeC, setActiveC] = React.useState(0);

  const location = useLocation();

  // const [indexCus, setIndexCus] = React.useState(1);

  const swiperRef = React.useRef();
  // const swiperRefCus = React.useRef();

  const navigate = useNavigate();
  return (
    <div className="wrap_tour">
      <Helmet>
        <title>{"Tours"}</title>
        <link rel="canonical" href={`${urlMain}${location.pathname}`} />
      </Helmet>
      <div className="tour-1">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h2>
                What you <br /> need prepare{" "}
              </h2>
              <ul>
                <li>
                  <p>
                    <span>01.</span>Clothes
                  </p>
                  <p>
                    Prepare seasonally appropriate clothes, raincoats,
                    swimsuits, sunscreen, and sunglasses
                  </p>
                </li>
                <li>
                  <p>
                    <span>02.</span>Medicine
                  </p>
                  <p>
                    Some medicine for the trip (fever, flu, insect repellent)
                  </p>
                </li>
                <li>
                  <p>
                    <span>03.</span>Cash
                  </p>
                  <p>
                    Prepare a little cash of about 500 thousands - 1 million VND
                    ~ 45 USD for personal expenses, such as gift season, or
                    other expenses.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <div className="wrap-out">
                <TourSecSVG1 />
                <TourSecSVG2 />
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/tmid1.jpg?alt=media&token=b7d9da7d-67ad-4e21-ac11-7b5669cd4571"
                  }
                  alt="toursec12"
                  className="si-3"
                />
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/tmid2.jpg?alt=media&token=41c785eb-ddd1-4ac2-a1f2-eca2eb50ffea"
                  }
                  alt="toursec11"
                  className="si-2"
                />
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/tmid3.jpg?alt=media&token=8c5a7f63-a4c5-4f17-a5b6-44e75b496d8d"
                  }
                  alt="toursec13"
                  className="si-1"
                />

                <div className="tab-short">
                  <p>
                    Sun
                    <br />
                    Flower
                    <br />
                    Tours
                    <br />
                  </p>
                </div>
                <div className="tab-trans">
                  <MapIc />
                  <p>Paradise on Ha Giang</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tour-2"
        style={{ background: `url(${BGTour2})`, backgroundSize: "cover" }}
      >
        <div className="container s1">
          <div className="row">
            <div className="col-6">
              <h2>
                Let's Book <br /> Now
              </h2>
              <p>
                Let's explore Sun Flower Tours' tours and quickly register to
                experience our best services.
              </p>
              <button
                className="btn-main"
                onClick={() => {
                  navigate("/book");
                }}
              >
                <p> Book Now </p>
                <IoIosArrowRoundForward size={25} />
              </button>
            </div>
            <div className="col-6">
              {tours.map((i, k) => (
                <div
                  key={k}
                  className={`tab-t-c ${activeT === k ? "active" : ""}`}
                  onClick={() => {
                    setActiveT(k);
                    setActiveC(0);
                  }}
                >
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/fadeihg.jpg?alt=media&token=8286d609-0dd5-497f-a258-bbacc8ca27f7"
                    }
                    alt="bgtl"
                  />
                  <div className="bgtl-content">
                    <h1>{i.time}</h1>

                    <p>
                      {`${i.time_detail.split(" ")[0]} ${
                        i.time_detail.split(" ")[1]
                      }`}
                      <br />
                      {`${i.time_detail.split(" ")[2]} ${
                        i.time_detail.split(" ")[3]
                      }`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="container s2">
          <div className="row s2-1">
            {tours[activeT].detail.map((i, k) => (
              <div
                className={`col-3 ${activeC === k ? "activec" : ""}`}
                onClick={() => setActiveC(k)}
                key={k}
              >
                <div>
                  <p>{i.title}</p>
                  <p>{i.short_explain_title}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="row s2-2">
            <div className="col-4">
              <h5>Routes</h5>

              <ul>
                {tours[activeT].detail[activeC].routes.map((i, k) => (
                  <li key={k}>
                    <div className="ct-routes">
                      <div className="lc">
                        <HiLocationMarker />
                      </div>
                      <div className="ct-lc">
                        <h6>{i.name}</h6>
                        <p>{i.short_desc}</p>
                      </div>
                    </div>
                    <div className="line-dark"></div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-8">
              <img
                src={tours[activeT].detail[activeC].map_img}
                alt={"map-i-tours"}
              />
            </div>
          </div>
          <div className="row s3-3">
            <div className="col-6">
              <h5>Details and Galleries</h5>
              <p>{tours[activeT].detail[activeC].short_explain_title}</p>
              <p>{tours[activeT].detail[activeC].desc}</p>
            </div>
            <div className="col-6">
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <figure
                  data-fancybox="gallery"
                  data-src={tours[activeT].detail[activeC].galleries[0]}
                >
                  <img
                    src={tours[activeT].detail[activeC].galleries[0]}
                    alt="galleries2"
                  />
                </figure>

                <div className="row">
                  <div className="col-6">
                    <figure
                      data-fancybox="gallery"
                      data-src={tours[activeT].detail[activeC].galleries[1]}
                    >
                      <img
                        src={tours[activeT].detail[activeC].galleries[1]}
                        alt="galleries2"
                      />
                    </figure>
                  </div>
                  <div className="col-6">
                    <figure
                      data-fancybox="gallery"
                      data-src={tours[activeT].detail[activeC].galleries[2]}
                    >
                      <img
                        src={tours[activeT].detail[activeC].galleries[2]}
                        alt="galleries2"
                      />
                    </figure>
                    <figure
                      data-fancybox="gallery"
                      data-src={tours[activeT].detail[activeC].galleries[3]}
                    >
                      <img
                        src={tours[activeT].detail[activeC].galleries[3]}
                        alt="galleries2"
                      />
                    </figure>
                  </div>
                </div>
              </Fancybox>
            </div>
          </div>
          <div className="row s4-4">
            <h5>RIDINGS</h5>
            {tours[activeT].packages.map((p, k) => (
              <div className="col-4" key={k}>
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/hagiangnewtour.appspot.com/o/ride%CC%81.jpg?alt=media&token=155ceafe-904c-4daa-b3ef-f917c06bb0cb"
                  }
                  alt="bg-pack-moto"
                />

                <div className="s4-4-cts-b">
                  <div>
                    <p>{p.name}</p>
                    <h4>{Number(p.pricevnd).toLocaleString("en-US")}đ</h4>
                    <p>
                      ≈ <span>{p.pricedola} USD</span>
                    </p>

                    <p>
                      <span>Note:</span>&nbsp;
                      {p.note}
                    </p>
                  </div>

                  <button
                    className="btn-main"
                    onClick={() => {
                      navigate("/book");
                    }}
                  >
                    <p> Pick </p>
                    <IoIosArrowRoundForward size={25} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="tour-3">
        <Swiper
          modules={[EffectFade]}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          speed={2500}
          slidesPerView={1}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {slidesTour.map((d, k) => (
            <SwiperSlide key={k} className="wrap-slid-s3">
              <img src={d.img} alt={`bg`} />
              <div>
                <div>
                  <h3>{d.title}</h3>
                  <p>{d.short_text}</p>
                </div>
                <div>
                  <p>{d.title_box}</p>
                  <p>{d.desc_box}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="btn-slide">
            <p onClick={() => swiperRef.current?.slidePrev()}>Previous</p>
            <p onClick={() => swiperRef.current?.slideNext()}>Next</p>
          </div>
        </Swiper>
      </div>
      {/* <div className="tour-4">
        <div className="row">
          <div className="col-6">
            <h1>Customer's Reviews</h1>

            <div className="t4-ct">
              <div>
                <img src={dataCus[indexCus].img} alt="cus" />
                <div>
                  <p>{dataCus[indexCus].name_cus}</p>
                  <p>{dataCus[indexCus].name_job_cus}</p>
                </div>
              </div>
              <p>{dataCus[indexCus].content}</p>
            </div>
            <div className="sli-btn-cus d-flex">
              <HiMiniArrowLongLeft
                size={38}
                onClick={() => swiperRefCus.current?.slidePrev()}
              />
              <div>
                <p>
                  {indexCus + 1} / {dataCus.length}
                </p>
              </div>
              <HiMiniArrowLongRight
                size={38}
                onClick={() => swiperRefCus.current?.slideNext()}
              />
            </div>
          </div>
          <div className="col-6">
            <Swiper
              modules={[EffectCards]}
              effect="cards"
              mousewheel={{ invert: false }}
              grabCursor={true}
              centeredSlides={true}
              onBeforeInit={(swiper) => {
                swiperRefCus.current = swiper;
              }}
              initialSlide={indexCus}
              onRealIndexChange={(element) => setIndexCus(element.activeIndex)}
            >
              {dataCus.map((d, k) => (
                <SwiperSlide key={k}>
                  <img src={d.img_review} alt={`bg`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div> */}
    </div>
  );
}
